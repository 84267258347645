import { Text, View, TouchableOpacity, Image, TextInput, Button, Platform, SafeAreaView, ScrollView, Dimensions, Modal, Alert, Linking } from 'react-native';
import React, { useState, useEffect, useCallback, useContext, forwardRef, useRef, useImperativeHandle } from 'react';
import { server_var, themeColor } from '../config/servervar';
import { store } from './store'
import moment from 'moment';
import EventEmitter from './EventEmitter';
import { CustomIcon, CustomWebDateTimePicker, CustomMobileDateTimePicker } from '../component/custom';
import { getJwtToken } from '../component/custom';

export function PharmaNotePart({ navigation, route }) {
  const { queId, userId, pharmaId, shopId, pharmaNoteId } = route?.params;
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState();
  const [dialog, setDialog] = useState({ visible: false, catId: null });
  const [cat, setCat] = useState({ subjectId1: null, subjectId2: null });
  const [appoint, setAppoint] = useState();
  const [error, setError] = useState({ at: '', message: '' });

  const [editMode, setEditMode] = useState(true);

  const getDefaultData = async (pPsctype, pharmaNoteData) => {
    const tmpOldQueLine = await globalState.client.service('oldquelines').find({ query: { oldId: queId } });
    if (pPsctype == 'prescribe') {
      if ((pharmaNoteData && pharmaNoteData.attachment == null) || !pharmaNoteData) {
        const resFile = await globalState.client.service('files').find(
          { query: { $select: ['id', 'title', 'path'], category: 'pharmanotefiles_man', $sort: { category: 1, title: 1 }, $limit: 50 } }
        );
        const tmpData = resFile.data.map(item => ({ ...item, selected: true }));
        return { attachment: tmpData, startTime: tmpOldQueLine?.data?.[0]?.acceptStartTime }
      } else {
        return { startTime: tmpOldQueLine?.data?.[0]?.acceptStartTime }
      }
    } else if (pPsctype == 'not_prescribe') {
      return { startTime: tmpOldQueLine?.data?.[0]?.acceptStartTime }
    }
  }

  useEffect(() => {
    loadData()
  }, [queId])

  const loadData = async () => {
    let result
    let psctype = 'not_prescribe';
    if (queId) {
      const ordermain = await globalState.client.service('ordermains').find({ query: { queId: queId } });
      if (ordermain.total > 0) {
        psctype = 'prescribe';
      }
    }
    if (pharmaNoteId) {
      console.log('pass1')
      const res = await globalState.client.service('pharmanotes').get(pharmaNoteId);
      result = res;
    } else {
      let res = [];
      if (queId) {
        res = await globalState.client.service('pharmanotes').patch(null, { shopId, pharmaId, psctype }, { query: { queId } });
      }
      if (res.length > 0) {
        const res3 = await getDefaultData(psctype, res[0]);
        console.log('pass2', res3)
        result = { ...res[0], ...res3 }
      } else {
        const res3 = await getDefaultData(psctype, null);
        const res2 = await globalState.client.service('pharmanotes').create(
          { queId, shopId, pharmaId, userId, psctype, ...res3 }
        );
        console.log('pass3', res2, res3);
        result = res2;
      }
    }
    if (result.createdAt) {
      const duration = moment.duration(moment().diff(result.createdAt));
      const hours = duration.asHours();
      if (globalState.user.roles == 'admin') {
        setEditMode(true);
      } else if (hours > 24) {
        setEditMode(false);
      } else {
        setEditMode(true);
      }
    }
    if (!(result?.custGroup)) {
      result = { ...result, custGroup: 'ลูกค้าทั่วไป' };
    }
    setData(result);

    if (result.appointmentId) {
      setAppoint({
        appDateTimeText: (
          result?.appointment?.appDateTime ? moment(result?.appointment?.appDateTime).format('DD/MM/YYYY HH:mm') : ''
        )
      })
    }
  }

  const validateForm = () => {
    let fieldToCheck = ['subject1', 'subject2'];
    const blankField = fieldToCheck.find((item) => !data[item]);
    if (blankField) {
      setError({ at: blankField, message: 'ไม่สามารถเว้นว่างได้' })
      return false;
    }

    if (!(data?.custGroup === null || data?.custGroup == 'ลูกค้าทั่วไป')) {
      console.log('apoint1')
      if (data?.custStaffId === null) {
        setError({ at: 'custStaffId', message: 'ไม่สามารถเว้นว่างได้' })
        return false;
      }
      let isnum = /^\d+$/.test(data.custStaffId);
      console.log('isnum', isnum);
      if (!isnum) {
        setError({ at: 'custStaffId', message: 'ต้องเป็นตัวเลขเท่านั้น' });
        return false;
      }
    }
    return true;
  }

  const saveData = async (prop) => {
    if (data?.id) {
      await globalState.client.service('pharmanotes').patch(data.id, { ...data, ...prop });
      if (data?.custGroup !== 'ลูกค้าทั่วไป') {
        if (queId) {
          try {
            await globalState.client.service('ordermains').patch(null,
              { opdFlag: true },
              { query: { queId } }
            );
          } catch (e) { 
            console.log(e);
          }
        }
      }
      EventEmitter.notify('ViewPharmaNote:refresh');
      EventEmitter.notify('ManageOrder:refresh');
      EventEmitter.notify('ManagePharmaNote:refresh');
    }
  }

  const moveMsg = async () => {
    const jwtToken = await getJwtToken();
    const res = await fetch(`${server_var.server_api}/moveMsg`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'authorization': jwtToken },
      body: JSON.stringify({ queId })
    });
    const resJson = await res.json();
  }

  const closeCase = async () => {
    return new Promise((resolve, reject) => {
      if (Platform.OS != 'web') {
        Alert.alert('ยืนยันจบการปรึกษานี้', '', [
          { text: 'ยกเลิก', onPress: () => resolve(false) },
          {
            text: 'จบการสนทนา', onPress: async () => {
              moveMsg();
              resolve(true);
            }
          }
        ])
      } else {
        if (window.confirm('ยืนยันจบการปรึกษานี้')) {
          moveMsg();
          resolve(true);
        } else {
          resolve(false);
        }
      }
    })
  }

  const COMPONENT_LIST = [
    { id: 10, part: 1, title: 'ส่วนที่ 1: บันทึกภายในสำหรับเภสัชกร', titleStyle: { fontWeight: 'bold' } },
    {
      id: 20, part: 1, title: 'ลักษณะการให้คำปรึกษา', value: data?.subject1, fieldShow: 'nextline', fieldName: 'psctype', fieldType: 'radio',
      radioList: [{ id: 1, value: 'prescribe', title: 'มีการจ่ายยา' }, { id: 2, value: 'not_prescribe', title: 'ไม่มีการจ่ายยา' }]
    },
    { id: 30, part: 1, component: 'break' },
    {
      id: 32, part: 1, title: 'กลุ่มลูกค้า', fieldName: 'custGroup', value: data?.custGroup, fieldShow: 'inline', fieldType: 'dialog',
      onPress: () => setDialog({ visible: true, type: 'custgroup', catId: null })
    },
    {
      id: 34, part: 1, title: 'รหัสพนักงาน', fieldName: 'custStaffId', value: data?.custStaffId, fieldShow: 'inline', fieldType: 'textinput',
      textInputStyle: { height: 30, }, textInputProp: { keyboardType: 'number-pad' },
      visible: (data?.custGroup == null || data?.custGroup == 'ลูกค้าทั่วไป') ? false : true
    },
    { id: 38, part: 1, component: 'break' },
    {
      id: 40, part: 1, title: 'หัวข้อการให้คำปรึกษา(หัวข้อหลัก)', fieldName: 'subject1', value: data?.subject1, fieldShow: 'inline', fieldType: 'dialog',
      onPress: () => setDialog({ visible: true, type: 'cat', catId: null })
    },
    {
      id: 50, part: 1, title: 'หัวข้อการให้คำปรึกษา(หัวข้อย่อย)', fieldName: 'subject2', value: data?.subject2, fieldShow: 'inline', fieldType: 'dialog',
      //visible: data?.psctype == 'prescribe',
      onPress: () => setDialog({ visible: true, type: 'cat', catId: cat.subjectId1 })
    },
    { id: 60, part: 1, title: 'บันทึกภายใน', value: data?.internal, fieldName: 'internal', fieldShow: 'nextline', fieldType: 'textinput', textInputProp: { multiline: true }, textInputStyle: { width: (Platform.OS == 'web') ? '400' : Dimensions.get('window').width - 40, height: 80, } },
    { id: 70, part: 2, title: 'ส่วนที่ 2: คำแนะนำสำหรับผู้รับบริการ', titleStyle: { fontWeight: 'bold' } },
    { id: 3, part: 2, title: 'รหัสบริการ', value: data?.id, fieldShow: 'inline' },
    { id: 4, part: 2, title: 'ผู้รับบริการ', value: data?.user?.displayName, fieldShow: 'inline' },
    { id: 5, part: 2, title: data?.shop?.shopType == 'special1' ? 'ผู้ให้บริการ' : 'เภสัชกร', value: data?.pharmacist?.displayName, fieldShow: 'inline' },
    { id: 6, part: 2, title: 'เวลาปรึกษา', value: moment(data?.startTime).format('DD/MM/YYYY HH:mm'), fieldShow: 'inline' },
    { id: 7, part: 2, component: 'break' },
    { id: 8, part: 2, title: 'อาการเบื้องต้น', value: data?.symtom, fieldName: 'symtom', fieldShow: 'nextline', fieldType: 'textinput', textInputProp: { multiline: true }, textInputStyle: { width: (Platform.OS == 'web') ? '400' : Dimensions.get('window').width - 40, height: 80, } },
    { id: 15, part: 2, component: 'break', visible: data?.psctype == 'prescribe' },
    { id: 16, part: 2, title: 'คำแนะนำเพิ่มเติม', visible: data?.psctype == 'prescribe', value: data?.notes, fieldName: 'notes', fieldShow: 'nextline', fieldType: 'textinput', textInputProp: { multiline: true }, textInputStyle: { width: (Platform.OS == 'web') ? '400' : Dimensions.get('window').width - 40, height: 80, } },
    { id: 17, part: 2, component: 'break', visible: data?.psctype == 'prescribe' },
    {
      id: 19, part: 2, title: 'แนบไฟล์', value: data?.attachment, visible: data?.psctype == 'prescribe', fieldType: 'attachfiles', fieldShow: 'inline_nextline',
      onPress: () => {
        setDialog({ visible: true, type: 'attachment', attachment: data.attachment })
      }
    },
    {
      id: 20, part: 2, component: 'break',
      visible: true
    },
    {
      id: 21, part: 2, title: 'นัดหมาย',
      visible: true,
      value: appoint?.appDateTimeText, fieldShow: 'inline', fieldType: 'dialog', onPress: () => setDialog({ visible: true, type: 'appointment', })
    },
  ]

  const onBackDialog = (backItem) => {
    if (backItem.type == 'cat') {
      if (!backItem.parentId) {
        setCat(prev => ({ ...prev, subjectId1: backItem.id, subjectId2: null }))
        setData(prev => ({ ...prev, subject1: backItem.catName, subject2: null }))
      } else {
        setCat(prev => ({ ...prev, subjectId2: backItem.id }))
        setData(prev => ({ ...prev, subject2: backItem.catName }))
      }
    } else if (backItem.type == 'appointment') {
      setData(prev => ({ ...prev, appointmentId: backItem.id }));
      setAppoint({ appDateTimeText: backItem.appDateTimeText })
    } else if (backItem.type == 'attachment') {
      setData(prev => ({ ...prev, attachment: backItem.attachmentList }))
    } else if (backItem.type == 'custgroup') {
      setData(prev => ({ ...prev, custGroup: backItem.catName }))
    }
  }

  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  const comRef = useRef()
  const dialogProp = { dialog, onBackDialog, setDialog, dialogWidth };

  const dialogContent = [
    {
      type: 'cat',
      title: 'เลือกหัวข้อ',
      com: <Category {...dialogProp} psctype={data?.psctype} />
    },
    {
      type: 'custgroup',
      title: 'เลือกหัวข้อ',
      com: <CustGroup {...dialogProp} />
    },
    {
      type: 'cancelappointment',
      title: 'ยกเลิกนัดหมาย',
      com: <CancelAppointment ref={comRef} {...dialogProp} pharmaNoteId={data?.id} />,
      actionList: [
        { id: 1, title: 'ยืนยันการยกเลิกนัดหมาย', onPress: () => comRef.current.remove(), touchStyle: { flex: 0.8 } },
      ]
    },
    {
      type: 'appointment',
      title: 'นัดหมาย',
      com: <Appointment ref={comRef}  {...dialogProp} pharmaNoteId={data?.id} />,
      actionList: [
        {
          id: 1, title: 'ลบ', onPress: () => comRef.current.remove(),
          style: { backgroundColor: 'white', borderColor: themeColor.color1, borderWidth: 1, },
          textStyle: { color: themeColor.color1 },
        },
        { id: 2, title: 'บันทึก', onPress: () => comRef.current.save() }
      ]
    },
    {
      type: 'appointment2',
      title: 'นัดหมาย',
      com: <Appointment ref={comRef}  {...dialogProp} pharmaNoteId={data?.id} />,
      actionList: [
        {
          id: 1, title: 'ยกเลิก', onPress: () => setDialog(prev => ({ ...prev, visible: false })),
          style: { backgroundColor: 'white', borderColor: themeColor.color1, borderWidth: 1, },
          textStyle: { color: themeColor.color1 },
        },
        { id: 2, title: 'บันทึก', onPress: () => comRef.current.save(), },
      ]
    },
    {
      type: 'attachment',
      title: 'ไฟล์สำหรับหัวข้อคำปรึกษา',
      com: <Attachment ref={comRef} {...dialogProp} attachment={dialog.attachment} />,
      actionList: [
        {
          id: 2, title: 'ยกเลิก', onPress: () => {
            console.log('hello')
            comRef.current.cancel();
          },
          style: { backgroundColor: 'white', borderColor: themeColor.color1, borderWidth: 1, },
          textStyle: { color: themeColor.color1 },
        },
        {
          id: 1, title: 'บันทึก', onPress: () => {
            comRef.current.save();
          }
        },
      ]
    }
  ]

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: 'white' }}>
      <ScrollView style={[Platform.OS == 'web' ? { height: '70vh' } : null]}>
        <View style={[Platform.OS != 'web' ? { paddingBottom: 260 } : null]}>
          {/* comment for web style={{ alignItems: 'center', paddingBottom: 260 }} */}
          <View style={{
            width: (Platform.OS == 'web') ? '400' : Dimensions.get('window').width, paddingHorizontal: 20, paddingVertical: 10, backgroundColor: themeColor.color3
          }}>
            {
              COMPONENT_LIST.filter(item => item.part == 1).map(item =>
                <CustomCom key={item.id.toString()} item={item} data={data} setData={setData} error={error} editMode={editMode} />)
            }
          </View>
          <View style={{
            width: (Platform.OS == 'web') ? '400' : Dimensions.get('window').width, paddingHorizontal: 20, paddingVertical: 10,
            backgroundColor: themeColor.color8, borderRadius: 10, marginBottom: 20
          }}>
            {
              COMPONENT_LIST.filter(item => item.part == 2).map(item =>
                <CustomCom key={item.id.toString()} item={item} data={data} setData={setData} error={error} editMode={editMode} />)
            }
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginVertical: 30, flex: 1 }}>
            {[
              {
                id: 1, title: 'บันทึกแบบร่าง (Draft)',
                backgroundColor: themeColor.color2,
                onPress: async () => {
                  console.log('pass1');
                  if (!validateForm()) {
                    return
                  }
                  console.log('pass2');
                  try {
                    await saveData();
                  } catch (e) {
                    alert('ไม่สามารถบันทึกข้อมูลได้ กรุณาตรวจสอบการเชื่อมต่อ และลองอีกครั้ง');
                    return;
                  }
                  console.log('pass4');
                  dispatch({ type: 'showToast', payload: { text: 'บันทึกแบบร่าง' }, });
                  console.log('pass5');
                  if (Platform.OS == 'web') {
                    navigation.goBack(1);
                  } else if (['ios', 'android'].includes(Platform.OS)) {
                    navigation.goBack();
                  }
                }
              },
              {
                id: 2, title: 'บันทึกและปิด Case นี้',
                backgroundColor: themeColor.color1,
                onPress: async () => {
                  console.log('pass1');
                  if (!validateForm()) {
                    return
                  }
                  console.log('pass2');
                  let res;
                  try {
                    res = await globalState.client.service('messages').find({ query: { queId } });
                    console.log(res)
                  } catch (e) { }

                  let resCloseCase
                  if (res?.total > 0) {
                    resCloseCase = await closeCase();
                  }
                  console.log('pass3');
                  try {
                    await saveData({ status: 'done' });
                  } catch (e) {
                    alert('ไม่สามารถบันทึกข้อมูลได้ กรุณาตรวจสอบการเชื่อมต่อ และลองอีกครั้ง');
                    return;
                  }
                  console.log('pass4');
                  dispatch({ type: 'showToast', payload: { text: 'บันทึกการรักษา' }, });
                  console.log('pass5');
                  if (Platform.OS == 'web') {
                    navigation.goBack(1);
                  } else if (['ios', 'android'].includes(Platform.OS)) {
                    navigation.goBack();
                  }
                }
              },
            ].map(item => {
              if (!item?.hide && editMode) {
                return (
                  <TouchableOpacity key={item.id.toString()} style={{ marginTop: 20 }} onPress={() => item.onPress()}>
                    <View style={{
                      width: 180, height: 40, borderRadius: 10, backgroundColor: item.backgroundColor,
                      alignSelf: 'center', justifyContent: 'center', alignItems: 'center'
                    }}>
                      <Text style={{ color: 'white', fontSize: 14 }}>{item.title}</Text>
                    </View>
                  </TouchableOpacity>
                )
              } else {
                return <View key={item.id.toString()} />
              }
            }
            )}
          </View>
        </View>
      </ScrollView>
      <Dialog
        dialog={dialog} setDialog={setDialog} onBackDialog={onBackDialog}
        pharmaNoteId={data?.id} psctype={data?.psctype} dialogContent={dialogContent}
        dialogWidth={dialogWidth}
      />
    </SafeAreaView >
  )
}

export function Dialog({ dialog, setDialog, dialogContent, dialogWidth }) {
  return (
    <Modal animationType="fade" transparent={true} visible={dialog.visible}>
      <View style={{
        position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.7)',
        justifyContent: 'center', alignItems: 'center'
      }}>
        <View style={{
          width: dialogWidth, height: (['appointment', 'attachment'].includes(dialog.type) ? dialogWidth * 1.5 : dialogWidth),
          paddingTop: 10, paddingBottom: 10, borderWidth: 2, borderColor: 'black', borderRadius: 10, backgroundColor: 'white'
        }}>
          <View style={{ alignItems: 'center', marginTop: 0 }}>
            <Text>{dialogContent?.find(item3 => item3.type == dialog.type)?.title}</Text>
          </View>
          <ScrollView style={{ marginTop: 10 }}>
            {dialogContent?.find(item3 => item3.type == dialog.type)?.com}
          </ScrollView>
          {dialogContent?.find(item3 => item3.type == dialog.type)?.actionList?.length > 0 && (
            <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
              {dialogContent?.find(item3 => item3.type == dialog.type)?.actionList?.map(item5 => (
                <TouchableOpacity key={item5.id.toString()} onPress={() => item5.onPress()} style={[{ flex: 0.45 }, item5?.touchStyle]}>
                  <View style={[{
                    backgroundColor: themeColor.color1, height: 35,
                    justifyContent: 'center', alignItems: 'center', borderRadius: 10
                  }, item5?.style]}>
                    <Text style={[{ color: 'white' }, item5.textStyle]}>{item5.title}</Text>
                  </View>
                </TouchableOpacity>
              ))}
            </View>
          )}
          <View style={{ position: 'absolute', right: 5, top: 5 }}>
            <TouchableOpacity onPress={() => setDialog(prev => ({ ...prev, visible: false }))}>
              <CustomIcon name="close-circle-outline" size={25} color="gray" />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  )
}

export const Appointment = forwardRef(({ dialog, setDialog, onBackDialog, dialogWidth, pharmaNoteId }, ref) => {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState({ date: null, time: null });
  const [error, setError] = useState({ at: '', message: '' });

  const validateForm = () => {
    const blankField = ['date', 'time'].find(item => !data?.[item]);
    if (blankField) {
      setError({ at: blankField, message: 'ไม่สามารถเว้นว่างได้' })
      return false;
    }
    return true;
  }

  useImperativeHandle(ref, () => ({
    async save() {
      if (!validateForm()) {
        return;
      }
      const appDateTime = (data?.date && data?.time) ? moment(data['date'] + ' ' + data['time'], 'DD/MM/YYYY HH:mm').utc() : null;
      globalState.client.service('appointments').patch(data.id, { ...data, appDateTime });
      setDialog(prev => ({ ...prev, visible: false }));
      if (onBackDialog) {
        onBackDialog({
          ...data,
          type: 'appointment',
          appDateTimeText: appDateTime ? moment(appDateTime).local().format('DD/MM/YYYY HH:mm') : ''
        })
      }
    },
    async remove() {
      await globalState.client.service('appointments').remove(data.id);
      setDialog(prev => ({ ...prev, visible: false }));
      if (onBackDialog) {
        onBackDialog({ ...data, type: 'appointment', appDateTimeText: '' });
      }
    }
  }))

  useEffect(() => {
    loadData();
  }, [pharmaNoteId])

  const loadData = async () => {
    const res = await globalState.client.service('appointments').find(
      { query: { appType: 'pharmanotes', refId: pharmaNoteId } }
    );
    if (res.total > 0) {
      setData({
        ...res.data[0],
        date: res.data[0].appDateTime ? moment(res.data[0].appDateTime).format('DD/MM/YYYY') : null,
        time: res.data[0].appDateTime ? moment(res.data[0].appDateTime).format('HH:mm') : null,
      });
    } else {
      const res2 = await globalState.client.service('pharmanotes').get(pharmaNoteId);
      const res1 = await globalState.client.service('appointments').create({
        userId: res2.userId,
        pharmaId: res2.pharmaId,
        shopId: res2.shopId,
        appType: 'pharmanotes',
        refId: pharmaNoteId,
        appStatus: null,
      })
      setData({ ...res1, date: null, time: null });
    }
  }

  return (
    <View style={{ width: dialogWidth - 4, paddingHorizontal: 10 }}>
      {[
        { id: 2, title: 'วัน', component: 'date' },
        { id: 3, title: 'เวลา', component: 'time' },
        { id: 4, title: 'ข้อความเตือน', component: 'textinput' }
      ].map(item =>
        <AppointmentItem key={item.id.toString()}
          item={item} error={error}
          setData={setData} data={data} dialogWidth={dialogWidth}
        />
      )}
      <View style={{ width: dialogWidth - 50, height: 20 }} />
    </View>
  )
})

function CustomDateTimePicker({ item, pickerType, setData, data, error, dialogWidth, zIndexValue }) {
  const [showFlag, setShowFlag] = useState(false);
  const pressPicker = () => {
    setShowFlag(prev => !prev);
    if (!data?.[pickerType]) {
      setData(prev => ({ ...prev, [pickerType]: moment().format((pickerType == 'date' ? 'DD/MM/YYYY' : 'HH:mm')) }))
    }
  }

  const onPickerChange = (_, selectedDate) => {
    if (Platform.OS == 'android' || pickerType == 'date') {
      setShowFlag(false)
    }
    setData(prev => ({ ...prev, [pickerType]: moment(selectedDate).format((pickerType == 'date' ? 'DD/MM/YYYY' : 'HH:mm')) }))
  }
  const onWebPickerChange = (_, value) => {
    console.log('value', value)
    setData(prev => ({ ...prev, [pickerType]: value }))
  }

  return (
    <View style={{ zIndex: zIndexValue ?? 0 }}>
      <View style={{ marginVertical: 5, flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ flex: 0.4 }}>
          <Text>{item.title}</Text>
        </View>
        <View style={{ flex: 0.6 }}>
          <View style={[error?.at == pickerType ? { borderColor: 'red', borderWidth: 1 } : null]}>
            {Platform.OS != 'web' && <TouchableOpacity onPress={() => pressPicker()}>
              <View style={{
                flexDirection: 'row', backgroundColor: 'white', alignItems: 'center', height: 30,
                borderRadius: 5, borderWidth: 1, borderColor: 'lightgray', paddingHorizontal: 5
              }}>
                <View style={{ flex: 1 }}>
                  <Text style={{ backgroundColor: 'white' }}>{data?.[pickerType] ?? ''}</Text>
                </View>
                <CustomIcon name={showFlag ? "chevron-up" : "chevron-down"} size={20} />
              </View>
            </TouchableOpacity>
            }
            {Platform.OS == 'web' &&
              <View>
                <CustomWebDateTimePicker pickerType={pickerType} onPickerChange={onWebPickerChange} value={data?.[pickerType] ?? ''} />
              </View>
            }
          </View>
        </View>
      </View>
      {Platform.OS != 'web' && showFlag && (
        <CustomMobileDateTimePicker data={data} onPickerChange={onPickerChange} pickerType={pickerType} dialogWidth={dialogWidth} />
      )}
    </View>
  )
}

function AppointmentItem({ item, error, data, setData, dialogWidth }) {
  if (item?.component == 'date') {
    return (
      <CustomDateTimePicker item={item} pickerType='date'
        setData={setData} data={data} error={error} dialogWidth={dialogWidth}
      />
    )
  } else if (item?.component == 'time') {
    return (
      <CustomDateTimePicker item={item} pickerType='time' zIndexValue={-1}
        setData={setData} data={data} error={error} dialogWidth={dialogWidth}
      />
    )
  } else if (item?.component == 'textinput') {
    return (
      <View style={{ marginVertical: 5, zIndex: -2 }}>
        <Text>{item.title}</Text>
        <TextInput
          multiline={true}
          value={data['appText']}
          onChangeText={text => setData(prev => ({ ...prev, appText: text }))}
          style={[{
            width: (dialogWidth - 20), borderRadius: 5, height: 80, alignSelf: 'center',
            borderWidth: 1, paddingHorizontal: 5, textAlignVertical: 'top', paddingVertical: 5
          }, error?.at == 'appText' ? { borderColor: 'red' } : { borderColor: 'lightgray' }]} />
      </View>
    )
  }
}

export const CancelAppointment = forwardRef(({ dialog, setDialog, onBackDialog, dialogWidth, pharmaNoteId }, ref) => {
  const { globalState, dispatch } = useContext(store);
  const [data, setData] = useState();

  useImperativeHandle(ref, () => ({
    async remove() {
      await globalState.client.service('appointments').patch(
        null,
        { appStatus: 'cancel', reasonToCancel: data?.reasonToCancel }, { query: { appType: 'pharmanotes', refId: pharmaNoteId } }
      );
      setDialog(prev => ({ ...prev, visible: false }));
      if (onBackDialog) {
        onBackDialog({ type: 'appointment', appDateTimeText: '' });
      }
    }
  }))

  const loadData = async () => {
    const res = await globalState.client.service('appointments').find({
      query: {
        appType: 'pharmanotes', refId: pharmaNoteId, $select: ['appDateTime', 'reasonToCancel']
      }
    });
    setData(res.data);
  }
  useEffect(() => {
    loadData();
  }, [pharmaNoteId])

  return (
    <View style={{ width: dialogWidth - 4, paddingHorizontal: 10 }}>
      <View style={{ alignItems: 'center' }}>
        <Text>ท่านต้องการยกเลิกนัดหมาย </Text>
        <Text>วันและเวลานัดหมาย {moment(data?.appDateTime).format('DD/MM/YYYY HH:mm')}</Text>
      </View>
      <View style={{ marginTop: 30 }}>
        <Text style={{ marginBottom: 10 }}>เหตุผลที่ยกเลิก</Text>
        {[
          { id: 1, title: 'ลูกค้าร้องขอ', value: 'customer_cancel' },
          { id: 2, title: 'ยกเลิกจากข้อผิดพลาด', value: 'mistake' }
        ].map(item => (
          <TouchableOpacity key={item.id.toString()} style={{ marginVertical: 5 }} onPress={() => {
            setData(prev => ({ ...prev, reasonToCancel: item.value }));
          }}>
            <View style={{ flexDirection: 'row' }}>
              <CustomIcon name={(data?.reasonToCancel == item.value) ? "check-circle-outline" : "checkbox-blank-circle-outline"} size={20} />
              <Text style={{ marginLeft: 5 }}>{item.title}</Text>
            </View>
          </TouchableOpacity>
        ))}
      </View>
      <View style={{ width: dialogWidth - 50, height: 20 }} />
    </View>
  )
})

function CustomCom({ item, data, setData, error, editMode }) {
  if (item?.visible !== null && item?.visible == false) {
    return <View />
  } else if (item?.visible || item?.visible == null) {
    if (item?.component == 'break') {
      return (
        <View
          key={item.id.toString()}
          style={{ flex: 1, borderBottomWidth: 1, borderColor: 'lightgray', marginTop: 10, marginBottom: 5 }}
        />
      )
    } else {
      return (
        <View key={item.id.toString()} >
          <View style={{ marginVertical: 5, flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flex: item?.fieldShow != 'inline' ? 1 : 0.6 }}>
              <Text style={[{ fontSize: 14 }, item.titleStyle ?? null]}>{item.title}</Text>
            </View>
            {['inline', 'inline_nextline'].includes(item?.fieldShow) && (
              <View style={{ flex: 0.4 }}>
                {item?.fieldType == 'dialog' && (
                  <TouchableOpacity onPress={() => {
                    if (editMode) {
                      item.onPress()
                    }
                  }}>
                    <View>
                      <View style={[
                        {
                          flexDirection: 'row', borderColor: 'lightgray', borderRadius: 5, borderWidth: 1, paddingHorizontal: 5,
                          height: Platform.OS != 'web' ? 30 : 'auto', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white'
                        },
                        error?.at == item.fieldName ? { borderColor: 'red', borderWidth: 1 } : null
                      ]}>
                        <Text style={{ fontSize: 14, color: 'gray' }}>{item.value}</Text>
                        <CustomIcon name="chevron-down" size={20} />
                      </View>
                      {error?.at == item.fieldName && error?.message && <Text style={{ color: 'red' }}>{error.message}</Text>}
                    </View>
                  </TouchableOpacity>
                )}
                {item?.fieldType == 'attachfiles' && (
                  <TouchableOpacity onPress={() => {
                    if (editMode) {
                      item.onPress()
                    }
                  }}>
                    <View style={{
                      flexDirection: 'row', borderColor: 'lightgray', borderRadius: 5, borderWidth: 1, paddingHorizontal: 5,
                      height: 30, alignItems: 'center', backgroundColor: 'white'
                    }}>
                      <CustomIcon name="paperclip" size={20} color="gray" />
                      <Text style={{ fontSize: 14, color: 'gray', marginLeft: 5 }}>เลือกไฟล์</Text>
                    </View>
                  </TouchableOpacity>
                )}
                {item?.fieldType == 'textinput' && (
                  <View>
                    <TextInput
                      {...item?.textInputProp}
                      value={item.value}
                      editable={editMode}
                      onChangeText={text => setData(prev => ({ ...prev, [item.fieldName]: text }))}
                      style={[item.textInputStyle, {
                        borderWidth: 1, borderColor: 'lightgray', borderRadius: 5, paddingHorizontal: 10, backgroundColor: 'white',
                        textAlignVertical: 'top', paddingVertical: 5
                      },
                      error?.at == item.fieldName ? { borderColor: 'red', borderWidth: 1 } : null
                      ]}
                    />
                    {error?.at == item.fieldName && error?.message && <Text style={{ color: 'red' }}>{error.message}</Text>}
                  </View>
                )}
                {!['dialog', 'attachfiles', 'textinput'].includes(item?.fieldType) && (
                  <Text style={{ fontSize: 14, color: 'gray' }}>{item.value}</Text>
                )}
              </View>
            )}
          </View>
          {['nextline', 'inline_nextline'].includes(item?.fieldShow) && (
            < View >
              {item?.fieldType == 'textinput' && (
                <TextInput
                  {...item?.textInputProp}
                  value={item.value}
                  editable={editMode}
                  onChangeText={text => setData(prev => ({ ...prev, [item.fieldName]: text }))}
                  style={[item.textInputStyle, {
                    borderWidth: 1, borderColor: 'lightgray', borderRadius: 10, paddingHorizontal: 10, backgroundColor: 'white',
                    textAlignVertical: 'top', paddingVertical: 5
                  }]}
                />
              )}
              {item?.fieldType == 'attachfiles' && (
                <View style={{ backgroundColor: 'white', flexDirection: 'row', flexWrap: 'wrap' }}>
                  {item?.value?.map(item6 => (
                    <TouchableOpacity
                      key={item6.id.toString()} style={{ marginHorizontal: 10, marginVertical: 5 }}
                      onPress={() => {
                        Linking.openURL(`${server_var.server_api}/${item6.path}`)
                      }}
                    >
                      <View style={{
                        paddingHorizontal: 5, paddingVertical: 3, borderRadius: 5, backgroundColor: 'lightgray',
                        flexDirection: 'row', alignItems: 'center'
                      }}>
                        <CustomIcon name="file-pdf-box" size={24} />
                        <Text>{item6.title}</Text>
                      </View>
                    </TouchableOpacity>
                  ))}
                </View>
              )}
              <View style={{ flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'space-around' }}>
                {item?.fieldType == 'radio' && (
                  item?.radioList?.map(item2 => (
                    <TouchableOpacity
                      key={item2.id.toString()}
                      onPress={
                        () => {
                          // setData(prev => ({ ...prev, [item.fieldName]: item2.value }));
                        }}>
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <CustomIcon name={(data && data[item?.fieldName] == item2?.value) ? "check-circle-outline" : "checkbox-blank-circle-outline"} color={"gray"} size={20} />
                        <Text style={{ marginLeft: 5, color: 'gray' }}>{item2.title}</Text>
                      </View>
                    </TouchableOpacity>
                  )))}
              </View>
              { }
              {!['textinput', 'radio', 'dialog', 'attachfiles'].includes(item?.fieldType) && (
                <Text style={{ fontSize: 14, color: 'gray' }}>
                  {JSON.stringify(item.value)}
                </Text>
              )}
            </View>
          )
          }
        </View >
      )
    }
  }
}

const Attachment = forwardRef(({ dialog, setDialog, onBackDialog, dialogWidth, attachment }, ref) => {
  const { globalState, dispatch } = useContext(store)
  const [data, setData] = useState();

  useImperativeHandle(ref, () => ({
    save() {
      onBackDialog({ type: 'attachment', attachmentList: data.filter(item3 => item3.selected) });
      setDialog(prev => ({ ...prev, visible: false }));
    },
    cancel() {
      setDialog(prev => ({ ...prev, visible: false }));
    }
  }))

  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    const res = await globalState.client.service('files').find(
      { query: { $select: ['id', 'title', 'path'], category: { $like: 'pharmanotefiles%' }, $sort: { category: 1, title: 1 }, $limit: 100 } }
    );
    const tmpData = res.data.map(item => {
      if (attachment?.map(item2 => item2.id).includes(item.id)) {
        return { ...item, selected: true }
      } else {
        return { ...item, selected: false }
      }
    })
    setData(tmpData);
  }

  return (
    <View style={{ paddingHorizontal: 20, marginTop: 20, flex: 1 }}>
      {data?.map(item => (
        <TouchableOpacity key={item.id.toString()} onPress={() => {
          setData(prev => (prev.map(item2 => {
            if (item2.id == item.id) {
              return { ...item2, selected: !item2.selected }
            } else {
              return item2
            }
          })))
        }}>
          <View style={[{
            flex: 1, height: 30,
            alignItems: 'center', marginVertical: 10, borderRadius: 5, flexDirection: 'row', paddingHorizontal: 5
          }, {
            backgroundColor: item.selected ? themeColor.color1 : 'white',
            borderColor: themeColor.color1, borderWidth: 1,
          }]}>
            <CustomIcon name={item.selected ? "checkbox-marked-outline" : "checkbox-blank-outline"}
              color={item.selected ? 'white' : themeColor.color1} size={15} />
            <Text style={[{ marginLeft: 5 }, { color: item.selected ? 'white' : themeColor.color1 }]}>{item.title}</Text>
          </View>
        </TouchableOpacity>
      ))}
    </View>
  )
})

function Category({ dialog, setDialog, onBackDialog, dialogWidth, psctype }) {
  const { globalState, dispatch } = useContext(store)
  const [data, setData] = useState();

  useEffect(() => {
    //loadData(dialog.catId, `pharmanotes_${psctype}`);
    loadData(dialog.catId, `pharmanotes_prescribe`);
  }, [dialog, psctype])

  const loadData = async (pCatId, pCatType) => {
    const res = await globalState.client.service('cats').find({ query: { parentId: pCatId, catType: pCatType } });
    setData(res.data);
  }

  return (
    <View style={{ paddingHorizontal: 5, alignItems: 'center', marginTop: 20 }}>
      {data?.map(item => (
        <TouchableOpacity key={item.id.toString()} onPress={() => {
          onBackDialog({ ...item, type: 'cat' })
          setDialog(prev => ({ ...prev, visible: false }));
        }}>
          <View style={{
            width: dialogWidth - 30, backgroundColor: themeColor.color1, height: 30,
            justifyContent: 'center',
            // alignItems: 'center', 
            paddingHorizontal: 10,
            marginVertical: 10, borderRadius: 5
          }}>
            <Text style={{ color: 'white' }}>{item.catName}</Text>
          </View>
        </TouchableOpacity>
      ))}
    </View>
  )
}

function CustGroup({ dialog, setDialog, onBackDialog, dialogWidth }) {
  const { globalState, dispatch } = useContext(store)
  const [data, setData] = useState();

  useEffect(() => {
    loadData(`custgroup`);
  }, [dialog])

  const loadData = async (pCatType) => {
    const res = await globalState.client.service('cats').find({ query: { catType: pCatType } });
    setData(res.data);
  }

  return (
    <View style={{ paddingHorizontal: 5, alignItems: 'center', marginTop: 20 }}>
      {data?.map(item => (
        <TouchableOpacity key={item.id.toString()} onPress={() => {
          onBackDialog({ ...item, type: 'custgroup' })
          setDialog(prev => ({ ...prev, visible: false }));
        }}>
          <View style={{
            width: dialogWidth - 30, backgroundColor: themeColor.color1, height: 30,
            justifyContent: 'center',
            paddingHorizontal: 10,
            marginVertical: 10, borderRadius: 5
          }}>
            <Text style={{ color: 'white' }}>{item.catName}</Text>
          </View>
        </TouchableOpacity>
      ))}
    </View>
  )
}
